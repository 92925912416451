import React, { useContext } from 'react';
import { ModalContext } from './ModalContext';

interface Props {
  children: any;
  title: string;
}

export default function PopupTrigger({ children, title }: Props) {
  const { setModal } = useContext(ModalContext);

  return (
    <div className="popup-trigger">
      <button
        type="button"
        className="popup-trigger__button"
        onClick={() => setModal({ modalOpen: true, children })}
      >
        <span className="popup-trigger__title h5">{title}</span>
        <div className="popup-trigger__plus" />
      </button>
    </div>
  );
}
