import React, { useEffect, useState } from 'react';
import {
  Tabs,
  TabList,
  Tab,
  TabPanelWrapper,
  TabPanel,
} from '../components/Tabs';
import PopupTrigger from '../components/PopupTrigger';
import { LogoCrest } from '../components/Logo';
import SEO from '../components/Seo';

interface Props {
  location: {
    state?: {
      tab?: string;
    };
  };
}

export default function OnlineOrOnCampusPage({ location: { state } }: Props) {
  const [defaultTab, setDefaultTab] = useState<number | string>(
    state?.tab || 0
  );

  useEffect(() => {
    setDefaultTab(state?.tab || 0);
  }, [state]);

  return (
    <>
      <SEO
        title="Online or On-Campus"
        description="Our on-campus and distance-education programs together form one student body and are not divided entities on separate tracks."
      />
      <main className="page-costs">
        <section className="container section-m-t page-m-b crest-bg-wrapper">
          <div className="crest-bg">
            <LogoCrest />
          </div>
          <div className="row">
            <div className="col-xs-12 col-lg-10 col-lg-offset-1">
              <h2>Online or On-Campus</h2>
              <p className="narrow">
                Our on-campus and distance-education programs together form one
                student body and are not divided entities on separate tracks.
              </p>
              <div className="section-m-t--sm">
                <Tabs id="online-or-on-campus" defaultTab={defaultTab}>
                  <TabList>
                    <Tab id="online">
                      <span>Online</span>
                    </Tab>
                    <Tab id="on-campus">
                      <span>On-Campus</span>
                    </Tab>
                  </TabList>
                  <TabPanelWrapper>
                    <TabPanel>
                      <div className="content-columns">
                        <div className="content-columns__row">
                          <div className="content-columns__col">
                            <p>
                              The majority of our courses are filmed live to our
                              on-campus students and delivered asynchronously to
                              our distance education students worldwide.
                            </p>
                            <p>
                              The graduate school offers some courses in a
                              synchronous format. In these seminar-style
                              courses, distance-education students interact in
                              real time with the instructor and on-campus
                              students via video conferencing software.
                            </p>
                            <blockquote>
                              In all cases, distance students receive the same
                              lectures as on-campus students and frequently
                              participate in discussions with them.
                            </blockquote>
                            <p>
                              Whereas many distance-education programs use
                              lectures that are rerun year after year,
                              distance-education students can be assured that
                              they are receiving fresh instructional material.
                            </p>
                            <p>
                              Our professional video production team is crucial
                              to the distance-education experience. Faculty of
                              the graduate school work closely with our
                              production team to deliver the best possible
                              experience of distance education.
                            </p>
                            <p>
                              We do not demand distance students meet a minimal
                              residency requirement. However, students have the
                              option of taking week-long intensive courses
                              on-site in Denver in January and in June to meet
                              our faculty and their fellow students.
                            </p>
                          </div>
                          <div className="content-columns__col">
                            <h3 className="h5 strong">Studying Online:</h3>
                            <PopupTrigger title="Direct Line of Communication with the Faculty">
                              <h4 className="h5">
                                Direct Line of Communication with the Faculty
                              </h4>
                              <p>
                                The Augustine Institute is known for its
                                distinguished faculty who specialize in
                                Scripture, the patristics, moral theology,
                                Church history, and more. Our faculty members
                                are dedicated to forming students in the
                                Catholic intellectual tradition in order to
                                enrich their spiritual lives and to prepare them
                                for evangelical work.
                              </p>
                              <p>
                                Rather than using teaching assistants to run
                                distance classes, our faculty interact directly
                                with online students through Zoom office hours,
                                discussion boards, extensive feedback on papers,
                                and email and phone conversations.{' '}
                              </p>
                            </PopupTrigger>
                            <PopupTrigger title="Flexibility">
                              <h4 className="h5">Flexibility</h4>
                              <p>
                                The distance education program is designed to be
                                completed part-time online in three to four
                                years.
                              </p>
                              <p>
                                However, students may design their schedule,
                                with the minimum completion rate being two years
                                and the maximum being eight years. Many of our
                                distance-education students have full-time
                                jobs—often in ecclesial or apostolic
                                settings—and care for children or other
                                dependents. Distance education allows these
                                students maximum flexibility to flourish in
                                their studies without uprooting their personal
                                lives.
                              </p>
                            </PopupTrigger>
                            <PopupTrigger title="Practical Preparation ">
                              <h4 className="h5">Practical Preparation </h4>
                              <p>
                                Our students and alumni bring the fruits of
                                their participation in the Graduate School into
                                their parishes, dioceses, schools, and other
                                ecclesial communities, so that together we might
                                strengthen the Body of Christ. As such, incoming
                                students join a global network of disciples.
                              </p>
                            </PopupTrigger>
                          </div>
                        </div>
                      </div>
                    </TabPanel>
                    <TabPanel>
                      <div className="content-columns">
                        <div className="content-columns__row">
                          <div className="content-columns__col">
                            <blockquote>
                              The majority of our on-campus students move to
                              Denver to study full-time for two years
                            </blockquote>
                            <p>
                              Our on-campus program combines rigorous academic
                              study, the shared pursuit of holiness, and
                              apostolic action in Denver’s rich Catholic
                              community.
                            </p>
                            <p>
                              The majority of our on-campus students move to
                              Denver to study full-time for two years, in which
                              they are formed by our distinguished faculty, the
                              Church’s primary sources, and the Divine Teacher
                              himself.
                            </p>
                            <p>
                              The life of the institute centers around the Holy
                              Sacrifice of the Mass, offered daily by our
                              full-time chaplain. Many on-campus students work
                              in ministry to gain practical experience.
                            </p>
                            <p>
                              Many also live together in student housing,
                              providing ample opportunity for theological
                              discussion.
                            </p>
                            <p>
                              Students enjoy recreation and contemplate the
                              beauty of creation in the nearby Rocky Mountains.
                            </p>
                          </div>
                          <div className="content-columns__col">
                            <h3 className="h5 strong">
                              On-Campus Community Features:
                            </h3>
                            <PopupTrigger title="Academic Life">
                              <h4 className="h5">Academic Life</h4>
                              <h5 className="h5 serif">
                                Lecture-style Classes
                              </h5>
                              <p>
                                Take courses that offer a rigorous handing-on of
                                Christian doctrine, the majority of which are
                                offered in lecture format in classrooms adorned
                                with beautiful sacred art.
                              </p>
                              <h5 className="h5 serif">
                                Seminar-style Classes
                              </h5>
                              <p>
                                Enjoy select courses offered in seminar format
                                for on-campus students only. This format
                                emphasizes guided conversation, active
                                participation, and a tailored pedagogy.
                              </p>
                              <h5 className="h5 serif">Weekly Seminar</h5>
                              <p>
                                Join your fellow students and a faculty member
                                for a weekly one-hour seminar to draw together
                                the themes treated across the curriculum into a
                                coherent, synthetic vision of theological
                                wisdom, and sharpen the tools you need to enter
                                fruitfully into ecclesial work.
                              </p>
                              <h5 className="h5 serif">Library</h5>
                              <p>
                                Study in the Archbishop Aquila Library, which
                                includes access and privileges at the St. John
                                Seminary and Denver Seminary libraries. Watch
                                for our new library set to open soon on our
                                second floor.
                              </p>
                              <h5 className="h5 serif">Faculty Wing</h5>
                              <p>
                                Your open invitation awaits to drop by the
                                faculty wing to discuss faith, academics and
                                life in general with your professors.
                              </p>
                            </PopupTrigger>
                            <PopupTrigger title="Sacraments and Spiritual Life">
                              <h4 className="h5">
                                Sacraments and Spiritual Life
                              </h4>
                              <h5 className="h5 serif">Full-Time Chaplain </h5>
                              <p>
                                Draw on the wise counsel of our full-time
                                chaplain, Fr. James Claver of the Servants of
                                Christ Jesus. Spiritual direction is provided by
                                request.
                              </p>
                              <h5 className="h5 serif">St. Augustine Chapel</h5>
                              <p>
                                Enjoy Daily Mass in our St. Augustine Chapel,
                                which remains open for prayer and Eucharistic
                                adoration during business hours.
                              </p>
                              <h5 className="h5 serif">Confession </h5>
                              <p>
                                Partake of the sacrament of reconciliation with
                                confession offered regularly and by appointment.
                              </p>
                              <h5 className="h5 serif">
                                Spiritual Conferences
                              </h5>
                              <p>
                                Draw strength from Spiritual Conferences that
                                are offered three times a semester and include
                                dinner, Vespers, and spiritual discussions by
                                either a faculty member or our chaplain.
                              </p>
                              <h5 className="h5 serif">Adoration </h5>
                              <p>
                                Come and adore our Lord in the Eucharist, as
                                exposition and benediction take place most
                                mornings.
                              </p>
                            </PopupTrigger>
                            <PopupTrigger title="Student Life and Events">
                              <h4 className="h5">Student Life and Events</h4>
                              <h5 className="h5 serif">Mountain Getaway</h5>
                              <p>
                                Each academic year starts with a beautiful
                                Mountain Getaway where students, faculty, and
                                families attend spiritual talks and enjoy
                                fellowship and hiking in the Rocky Mountains.
                              </p>
                              <h5 className="h5 serif">Tolle Lege Café </h5>
                              <p>
                                Settle in at the cozy Tolle Lege Café here on
                                campus, a social hub where students study or
                                gather in meeting areas to sip coffee, eat lunch
                                with faculty, and discuss the latest great books
                                they have read. Share your talents at Open Mic
                                Night hosted monthly.
                              </p>
                              <h5 className="h5 serif">Student Housing</h5>
                              <p>
                                Live in a community of faith with other
                                students. Male and female houses are available
                                in a nearby neighborhood for incoming students.
                              </p>
                              <h5 className="h5 serif">Student Work</h5>
                              <p>
                                Support your studies with a student job
                                (typically 15 hours/week) at FORMED, Augustine
                                Institute STUDIOS, the Café, or Academics.
                              </p>
                              <h5 className="h5 serif">
                                Faculty- and Student-Led Events
                              </h5>
                              <p>
                                Join in fun and informal events—such as saints’
                                parties, dinners and brunches— regularly
                                organized and led by faculty or students.
                              </p>
                            </PopupTrigger>
                            <PopupTrigger title="Life in Denver">
                              <h4 className="h5">Life in Denver</h4>
                              <h5 className="h5 serif">Catholic Community</h5>
                              <p>
                                Never lack for inspiration and community with
                                Denver’s rich Catholic community, including
                                thriving parishes, schools, religious orders,
                                and missionary apostolates.
                              </p>
                              <h5 className="h5 serif">The Great Outdoors</h5>
                              <p>
                                Surround yourself in the beauty of God’s
                                creation by joining regular hiking and camping
                                outings, along with snowboarding and skiing
                                trips, organized by fellow students.
                              </p>
                              <p>
                                We also partner with amazing Catholic leaders
                                who are willing to guide your next adventure.
                              </p>
                              <h5 className="h5 serif">City Scene</h5>
                              <p>
                                Soak up city life in Denver, which boasts an
                                abundance of restaurants, craft breweries,
                                professional sports teams, an excellent symphony
                                and multiple theatre companies.
                              </p>
                              <h5 className="h5 serif">Holy Sites</h5>
                              <p>
                                Go on a pilgrimage to visit the holy sites in
                                the area such as the Mother Cabrini Shrine, St.
                                Elizabeth of Hungary Parish, the Cathedral
                                Basilica of the Immaculate Conception, St.
                                Catherine’s Chapel on the Rock in Estes Park,
                                and Cherry Creek State Park — the location of
                                St. John Paul II’s historic World Youth Day in
                                1993.
                              </p>
                            </PopupTrigger>
                          </div>
                        </div>
                      </div>
                    </TabPanel>
                  </TabPanelWrapper>
                </Tabs>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
}
